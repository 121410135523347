export const API_ENDPOINT = {
  case: {
    casesByJudge: '/case/casesByJudge',
    getCourtNames: '/case/getCourtNames',
    search: {
      news: '/case/search/news',
      interpretation: '/case/search/interpretation',
      trialdecision: '/case/search/trialdecision',
    },
    isRequestAble: '/case/isRequestAble',
  },
  judge: {
    autoComplete: '/judge/auto-complete',
    list: '/judge/list',
    detail: {
      v2: '/judge/detail/v2',
    },
  },
  legalAnalytics: {
    searchWidget: '/legal-analytics/search-widget',
    canUse: '/legal-analytics/can-use',
    caseAnalytics: {
      sexualCrimes: {
        search: '/legal-analytics/case-analytics/sexual-crimes/search',
        statutes: '/legal-analytics/case-analytics/sexual-crimes/statutes',
        precedents: {
          mostQuoted: '/legal-analytics/case-analytics/sexual-crimes/precedents/most-quoted',
        },
        criminal: {
          search: '/legal-analytics/case-analytics/sexual-crimes/criminal/search',
          statutes: '/legal-analytics/case-analytics/sexual-crimes/criminal/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/sexual-crimes/criminal/precedents/most-quoted',
          },
        },
        civil: {
          search: '/legal-analytics/case-analytics/sexual-crimes/civil/search',
          statutes: '/legal-analytics/case-analytics/sexual-crimes/civil/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/sexual-crimes/civil/precedents/most-quoted',
          },
        },
        administration: {
          search: '/legal-analytics/case-analytics/sexual-crimes/administration/search',
          statutes: '/legal-analytics/case-analytics/sexual-crimes/administration/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/sexual-crimes/administration/precedents/most-quoted',
          },
        },
      },
      trafficCrimes: {
        search: '/legal-analytics/case-analytics/traffic-crimes/search',
        statutes: '/legal-analytics/case-analytics/traffic-crimes/statutes',
        precedents: {
          mostQuoted: '/legal-analytics/case-analytics/traffic-crimes/precedents/most-quoted',
        },
        criminal: {
          search: '/legal-analytics/case-analytics/traffic-crimes/criminal/search',
          statutes: '/legal-analytics/case-analytics/traffic-crimes/criminal/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/traffic-crimes/criminal/precedents/most-quoted',
          },
        },
        civil: {
          search: '/legal-analytics/case-analytics/traffic-crimes/civil/search',
          statutes: '/legal-analytics/case-analytics/traffic-crimes/civil/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/traffic-crimes/civil/precedents/most-quoted',
          },
        },
        administration: {
          search: '/legal-analytics/case-analytics/traffic-crimes/administration/search',
          statutes: '/legal-analytics/case-analytics/traffic-crimes/administration/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/traffic-crimes/administration/precedents/most-quoted',
          },
        },
      },
      digitalSexualCrimes: {
        search: '/legal-analytics/case-analytics/digital-sexual-crimes/search',
        statutes: '/legal-analytics/case-analytics/digital-sexual-crimes/statutes',
        precedents: {
          mostQuoted: '/legal-analytics/case-analytics/digital-sexual-crimes/precedents/most-quoted',
        },
        criminal: {
          search: '/legal-analytics/case-analytics/digital-sexual-crimes/criminal/search',
          statutes: '/legal-analytics/case-analytics/digital-sexual-crimes/criminal/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/digital-sexual-crimes/criminal/precedents/most-quoted',
          },
        },
        civil: {
          search: '/legal-analytics/case-analytics/digital-sexual-crimes/civil/search',
          statutes: '/legal-analytics/case-analytics/digital-sexual-crimes/civil/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/digital-sexual-crimes/civil/precedents/most-quoted',
          },
        },
        administration: {
          search: '/legal-analytics/case-analytics/digital-sexual-crimes/administration/search',
          statutes: '/legal-analytics/case-analytics/digital-sexual-crimes/administration/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/digital-sexual-crimes/administration/precedents/most-quoted',
          },
        },
      },
      fraudCrimes: {
        search: '/legal-analytics/case-analytics/fraud-crimes/search',
        statutes: '/legal-analytics/case-analytics/fraud-crimes/statutes',
        precedents: {
          mostQuoted: '/legal-analytics/case-analytics/fraud-crimes/precedents/most-quoted',
        },
        criminal: {
          search: '/legal-analytics/case-analytics/fraud-crimes/criminal/search',
          statutes: '/legal-analytics/case-analytics/fraud-crimes/criminal/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/fraud-crimes/criminal/precedents/most-quoted',
          },
        },
        civil: {
          search: '/legal-analytics/case-analytics/fraud-crimes/civil/search',
          statutes: '/legal-analytics/case-analytics/fraud-crimes/civil/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/fraud-crimes/civil/precedents/most-quoted',
          },
        },
        administration: {
          search: '/legal-analytics/case-analytics/fraud-crimes/administration/search',
          statutes: '/legal-analytics/case-analytics/fraud-crimes/administration/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/fraud-crimes/administration/precedents/most-quoted',
          },
        },
      },
      defamationCrimes: {
        search: '/legal-analytics/case-analytics/defamation-crimes/search',
        statutes: '/legal-analytics/case-analytics/defamation-crimes/statutes',
        precedents: {
          mostQuoted: '/legal-analytics/case-analytics/defamation-crimes/precedents/most-quoted',
        },
        criminal: {
          search: '/legal-analytics/case-analytics/defamation-crimes/criminal/search',
          statutes: '/legal-analytics/case-analytics/defamation-crimes/criminal/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/defamation-crimes/criminal/precedents/most-quoted',
          },
        },
        civil: {
          search: '/legal-analytics/case-analytics/defamation-crimes/civil/search',
          statutes: '/legal-analytics/case-analytics/defamation-crimes/civil/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/defamation-crimes/civil/precedents/most-quoted',
          },
        },
        administration: {
          search: '/legal-analytics/case-analytics/defamation-crimes/administration/search',
          statutes: '/legal-analytics/case-analytics/defamation-crimes/administration/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/defamation-crimes/administration/precedents/most-quoted',
          },
        },
      },
      drugCrimes: {
        search: '/legal-analytics/case-analytics/drug-crimes/search',
        statutes: '/legal-analytics/case-analytics/drug-crimes/statutes',
        precedents: {
          mostQuoted: '/legal-analytics/case-analytics/drug-crimes/precedents/most-quoted',
        },
        criminal: {
          search: '/legal-analytics/case-analytics/drug-crimes/criminal/search',
          statutes: '/legal-analytics/case-analytics/drug-crimes/criminal/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/drug-crimes/criminal/precedents/most-quoted',
          },
        },
        civil: {
          search: '/legal-analytics/case-analytics/drug-crimes/civil/search',
          statutes: '/legal-analytics/case-analytics/drug-crimes/civil/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/drug-crimes/civil/precedents/most-quoted',
          },
        },
        administration: {
          search: '/legal-analytics/case-analytics/drug-crimes/administration/search',
          statutes: '/legal-analytics/case-analytics/drug-crimes/administration/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/drug-crimes/administration/precedents/most-quoted',
          },
        },
      },
      violenceCrimes: {
        search: '/legal-analytics/case-analytics/violence-crimes/search',
        statutes: '/legal-analytics/case-analytics/violence-crimes/statutes',
        precedents: {
          mostQuoted: '/legal-analytics/case-analytics/violence-crimes/precedents/most-quoted',
        },
        criminal: {
          search: '/legal-analytics/case-analytics/violence-crimes/criminal/search',
          statutes: '/legal-analytics/case-analytics/violence-crimes/criminal/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/violence-crimes/criminal/precedents/most-quoted',
          },
        },
        civil: {
          search: '/legal-analytics/case-analytics/violence-crimes/civil/search',
          statutes: '/legal-analytics/case-analytics/violence-crimes/civil/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/violence-crimes/civil/precedents/most-quoted',
          },
        },
        administration: {
          search: '/legal-analytics/case-analytics/violence-crimes/administration/search',
          statutes: '/legal-analytics/case-analytics/violence-crimes/administration/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/violence-crimes/administration/precedents/most-quoted',
          },
        },
      },
      theftCrimes: {
        search: '/legal-analytics/case-analytics/theft-crimes/search',
        statutes: '/legal-analytics/case-analytics/theft-crimes/statutes',
        precedents: {
          mostQuoted: '/legal-analytics/case-analytics/theft-crimes/precedents/most-quoted',
        },
        criminal: {
          search: '/legal-analytics/case-analytics/theft-crimes/criminal/search',
          statutes: '/legal-analytics/case-analytics/theft-crimes/criminal/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/theft-crimes/criminal/precedents/most-quoted',
          },
        },
        civil: {
          search: '/legal-analytics/case-analytics/theft-crimes/civil/search',
          statutes: '/legal-analytics/case-analytics/theft-crimes/civil/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/theft-crimes/civil/precedents/most-quoted',
          },
        },
        administration: {
          search: '/legal-analytics/case-analytics/theft-crimes/administration/search',
          statutes: '/legal-analytics/case-analytics/theft-crimes/administration/statutes',
          precedents: {
            mostQuoted: '/legal-analytics/case-analytics/theft-crimes/administration/precedents/most-quoted',
          },
        },
      },
      laborStandardsActViolationCrimes: {
        search: '/legal-analytics/case-analytics/labor-standards-act-violation-crimes/search',
        statutes: '/legal-analytics/case-analytics/labor-standards-act-violation-crimes/statutes',
        precedents: {
          mostQuoted: '/legal-analytics/case-analytics/labor-standards-act-violation-crimes/precedents/most-quoted',
        },
        criminal: {
          search: '/legal-analytics/case-analytics/labor-standards-act-violation-crimes/criminal/search',
          statutes: '/legal-analytics/case-analytics/labor-standards-act-violation-crimes/criminal/statutes',
          precedents: {
            mostQuoted:
              '/legal-analytics/case-analytics/labor-standards-act-violation-crimes/criminal/precedents/most-quoted',
          },
        },
        civil: {
          search: '/legal-analytics/case-analytics/labor-standards-act-violation-crimes/civil/search',
          statutes: '/legal-analytics/case-analytics/labor-standards-act-violation-crimes/civil/statutes',
          precedents: {
            mostQuoted:
              '/legal-analytics/case-analytics/labor-standards-act-violation-crimes/civil/precedents/most-quoted',
          },
        },
        administration: {
          search: '/legal-analytics/case-analytics/labor-standards-act-violation-crimes/administration/search',
          statutes: '/legal-analytics/case-analytics/labor-standards-act-violation-crimes/administration/statutes',
          precedents: {
            mostQuoted:
              '/legal-analytics/case-analytics/labor-standards-act-violation-crimes/administration/precedents/most-quoted',
          },
        },
      },
      embezzlementAndBreachOfTrustCrimes: {
        search: '/legal-analytics/case-analytics/embezzlement-and-breach-of-trust-crimes/search',
        statutes: '/legal-analytics/case-analytics/embezzlement-and-breach-of-trust-crimes/statutes',
        precedents: {
          mostQuoted: '/legal-analytics/case-analytics/embezzlement-and-breach-of-trust-crimes/precedents/most-quoted',
        },
        criminal: {
          search: '/legal-analytics/case-analytics/embezzlement-and-breach-of-trust-crimes/criminal/search',
          statutes: '/legal-analytics/case-analytics/embezzlement-and-breach-of-trust-crimes/criminal/statutes',
          precedents: {
            mostQuoted:
              '/legal-analytics/case-analytics/embezzlement-and-breach-of-trust-crimes/criminal/precedents/most-quoted',
          },
        },
        civil: {
          search: '/legal-analytics/case-analytics/embezzlement-and-breach-of-trust-crimes/civil/search',
          statutes: '/legal-analytics/case-analytics/embezzlement-and-breach-of-trust-crimes/civil/statutes',
          precedents: {
            mostQuoted:
              '/legal-analytics/case-analytics/embezzlement-and-breach-of-trust-crimes/civil/precedents/most-quoted',
          },
        },
        administration: {
          search: '/legal-analytics/case-analytics/embezzlement-and-breach-of-trust-crimes/administration/search',
          statutes: '/legal-analytics/case-analytics/embezzlement-and-breach-of-trust-crimes/administration/statutes',
          precedents: {
            mostQuoted:
              '/legal-analytics/case-analytics/embezzlement-and-breach-of-trust-crimes/administration/precedents/most-quoted',
          },
        },
      },
      electronicFinancialTransactionsActViolationCrimes: {
        search: '/legal-analytics/case-analytics/electronic-financial-transactions-act-violation-crimes/search',
        statutes: '/legal-analytics/case-analytics/electronic-financial-transactions-act-violation-crimes/statutes',
        precedents: {
          mostQuoted:
            '/legal-analytics/case-analytics/electronic-financial-transactions-act-violation-crimes/precedents/most-quoted',
        },
        criminal: {
          search:
            '/legal-analytics/case-analytics/electronic-financial-transactions-act-violation-crimes/criminal/search',
          statutes:
            '/legal-analytics/case-analytics/electronic-financial-transactions-act-violation-crimes/criminal/statutes',
          precedents: {
            mostQuoted:
              '/legal-analytics/case-analytics/electronic-financial-transactions-act-violation-crimes/criminal/precedents/most-quoted',
          },
        },
        civil: {
          search: '/legal-analytics/case-analytics/electronic-financial-transactions-act-violation-crimes/civil/search',
          statutes:
            '/legal-analytics/case-analytics/electronic-financial-transactions-act-violation-crimes/civil/statutes',
          precedents: {
            mostQuoted:
              '/legal-analytics/case-analytics/electronic-financial-transactions-act-violation-crimes/civil/precedents/most-quoted',
          },
        },
        administration: {
          search:
            '/legal-analytics/case-analytics/electronic-financial-transactions-act-violation-crimes/administration/search',
          statutes:
            '/legal-analytics/case-analytics/electronic-financial-transactions-act-violation-crimes/administration/statutes',
          precedents: {
            mostQuoted:
              '/legal-analytics/case-analytics/electronic-financial-transactions-act-violation-crimes/administration/precedents/most-quoted',
          },
        },
      },
    },
    judgeAnalytics: {
      detail: '/legal-analytics/judge-analytics/detail',
      latestRead: '/legal-analytics/judge-analytics/latest-read',
      search: '/legal-analytics/judge-analytics/search',
    },
  },
  lboxUser: {
    verifyLawyer: '/lboxUser/verifyLawyer',
  },
  user: {
    current: '/user/current',
  },
  lawSchoolAuthorization: {
    base: '/lawSchoolAuthorization',
  },
  clientTracking: '/clientTracking',
  routeHandler: {
    judgeProfileErrorReport: '/api/get-judge-profile-error-report',
    requestAnotherCaseCategory: '/api/get-request-another-case-category',
    requestAnotherCrime: '/api/get-request-another-crime',
    searchCommandRequestNotified: '/api/get-search-command-request-notified',
    getPublicationRequest: '/api/get-publication-request',
  },
  archiveCase: {
    base: '/archiveCase',
    watchLater: '/archiveCase/watch-later',
  },
} as const
